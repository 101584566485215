<template>
	<LoginLayout
		form-title="SSO authentication"
	>
		<template #form>
			<Message>
				Sorry, SSO authentication has failed. Please try again later
			</Message>
			<a @click="$router.push({ name: LOGIN })">
				Log in.
			</a>
		</template>
	</LoginLayout>
</template>
<script>
import LoginLayout from '../../../../shared/layouts/login-layout.vue'
import Message from '../../../../shared/components/message.vue'
import { LOGIN } from '../../router/route-names.js'
export default {
	components: { Message, LoginLayout },
	data () {
		return {
			LOGIN
		}
	}
}
</script>
